//import store from "../../store";
const mainRoutes = [
  {
    path: "/",
    alias: "/home",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Home.vue"
      ),
 
  },
];

export default mainRoutes;
