import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import Vuex from 'vuex';
import VueAgile from 'vue-agile'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Vuex);
app.use(VueAgile);
app.use(Vue3VideoPlayer, {
    lang: 'en'
  })

app.mount("#app");

