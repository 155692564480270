import { createRouter, createWebHistory } from "vue-router";
 
import routes from "./routes";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import store from "../store";

const router = createRouter({
  // Optionen
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundPage,
      redirect: "/",
    },
  ],
});

router.afterEach((to) => {
  store.commit('setActiveSite',to.fullPath);
  console.log(to);
  document.title = 'Lüftungsbau-Chemnitz.de'+to.fullPath || 'Lüftungsbau-Chemnitz.de';
})

export default router;

