//import store from "../../store";
const datenschutzRoutes = [
  {
    path: "/Datenschutz",
    
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Datenschutz.vue"
      ), 
      
  },
];

export default datenschutzRoutes;
