<template>
  <div class="bg-vue">
    <!--   <router-view v-slot="{ Component }">
    <transition
      :enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <component :is="Component" :key="$route.path"></component>
    </transition>
    </router-view>-->
    <router-view></router-view>
    <widget-container-modal />
  </div>
</template>

<script>
//import VueCookies from "vue-cookies";
import { container } from "jenesius-vue-modal";
//import { openModal } from "jenesius-vue-modal";
//import { mapState } from "vuex"

export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        console.log("change from " + from);
      },
    },
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
  },
  components: {
    WidgetContainerModal: container,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";
@import "./eigen_css/kito.css";

.font {
  font-variant: lining-nums;
}
.row{
  margin: 0;
}
.bgWeiss15 {
  background-color: rgba(255, 254, 254, 0.75);
  padding: 30px;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.1);
}
.bgWeiss5 {
  background-color: rgba(255, 254, 254, 0.35);
  padding: 30px;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.1);
}
.h1Weiss {
  color: rgba(255, 254, 254);
}
.h1Rot {
  color: #8b2229;
}
.textGrau,
.h1Grau {
  color: rgb(70, 70, 70);
}

.h2Rot {
  color: #8b2229;
  text-shadow: none;
}
.h5AnkerLink {
  
  text-align: center;
  color: #8b2229;
  background-color: rgba(253, 253, 253, 0.705);
  text-decoration: none;
}
.textbalken
{
  position: relative;
  bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  /* background-color: rgba(253, 253, 253, 0.5);
  font-size: 20px;
  color: #404040;*/
  background-color: rgba(253, 253, 253, 0.705);
  font-size: 22px;
    font-weight: bold;
  color: #8b2229;
 /* padding: 10px;*/
}

.hoverBig:hover{
  border: 2px solid grey;
  border-radius: 25px;
  position: relative;
  bottom: 25px;
}
@media screen and (max-width: 1350px) {
  .textbalken
  {
    font-size: 14px;
  bottom: 17px;
  }
}
@media screen and (max-width: 990px) {
  .textbalken
  {
    font-size: 12px;
  bottom: 10px;
  }
}
@media screen and (max-width: 770px) {
  .textbalken
  {
    font-size: 22px;
  bottom: 25px;
  }
}



.rot {
  color: #8b2229;
}
.feldweiss, .feldweiss h4{
  background-color: white;
  color: black;
  padding: 15px;
}
.feldrot, .feldrot h4{
  background-color: #8b2229;
  color: white;
  padding: 15px;
}


.weissSschwarz {
  color: black;
  background-color: white;
}
.rotSweiss {
  color: #8b2229;
  text-shadow: 1px 1px 2px white;
}
.rotSgrau {
  color: #8b2229;
  text-shadow: 1px 1px 2px #404040;
}
.rotSgrauBig {
  font-size: 54px;
  color: #8b2229;
  text-shadow: 1px 1px 4px #404040;
}
.grauHellfeld {
  color: #404040;
  background-color: #cecfca;
}
.grauDunkelfeld {
  color: white;
  background-color: #404040;
  margin: 0;
  padding: 0;
}
.sticky {
  position: fixed;
  bottom: 50px;
  left: 0px;
  overflow: auto;
  color: #8b2229;
  text-shadow: 1px 1px 4px white;
}
.title {
  color: #8b2229;
  
  padding: 30px;
  opacity: 0.85;
}
.titleKlein {
  color: #8b2229;
  background-color: white;
  opacity: 0.85;
}
.titelBackground {
  background-image: url("assets/chemnitz-teaser.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  padding-left: 0px;
  padding-right: 0px;
}
.jobKopf{
   background-image: url("assets/Jobs.jpg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  padding-left: 0px;
  padding-right: 0px;
}
.center {
  justify-content: center !important;
}
.zub {
  justify-content: center !important;
  text-decoration: none;
  position: relative;
  top: -82px;
  text-align: center;
}

.imageToBig:hover{
  cursor:pointer;
}

.zubInnen {
  background-color: rgb(90, 90, 90);
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 2px;
  padding: 15px;
  text-align: center;
}
.zubInnenOver {
  background-color: white;
  color: rgb(90, 90, 90);
  border-color: rgb(90, 90, 90);
  border-style: solid;
  border-width: 2px;
  padding: 15px;
}
.zubRouterLink {
  text-decoration: none;
}
.pfeil_rechts {
  position: relative;
  right: -10px;
  max-width: 30px;
}
.inputerror {
  color: #8b2229;
}
.inputok {
  color: #228b4e;
}
.zitate {
  color: white;
}
.zitatverweis {
  color: white;
  font-weight:normal
}
.fussTextKlein {
  font-size: 10px;
}
.normalerText {
  background-color: white;
  color: rgb(11, 12, 14);
  padding: 30px;
  opacity: 0.85;
}
.bg-vue {
  background-color: rgb(103, 113, 122);
  background-image: url("assets/Chemnitz_Metall.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #000;
  color: white;
}
.bg-main {
  background-color: #8b2229;
  color: white;
}
.bg-center {
  background-color: white;
  color: #000;
  margin: 0;
  padding: 0;
}
.bg-navbar {
  background-color: #8b2229;
  color: white !important;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
  text-transform: uppercase;
}
.bg-footer {
  background-color: #8b2229;
  color: rgba(255, 255, 255, 0.623) !important;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
}
.img-ausbildung {
  position: absolute;
  right: -35px;
  top: 10px;
  max-width: 15rem;
}
.bg-footer p {
  color: rgba(255, 255, 255, 0.623) !important;
  margin: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.bg-footer a {
  color: rgba(255, 255, 255, 0.623) !important;
}
.linkSchwarz{
  color: black !important;
}
.nav-item {
  justify-content: center !important;
  text-align: center;
}
.nav-active {
  color: white !important;
  text-decoration: underline white;
  font-size: 16px;
  font-weight: 700;
}
.nav-deactiv {
  text-decoration: none;
  color: white !important;
  font-size: 16px;
  font-weight: 700;
}
.nav-impressum {
  text-decoration: none;
  color: white !important;
  font-size: 0.75rem;
}
.ddown-menu {
  background-color: #8b2229;
  font-size: 16px;
  position: relative;
}
.ddown-link {
  font-size: 12px;
  padding: 15px;
}
.text-vue {
  color: rgb(29, 95, 161);
}
.text-vue2 {
  color: rgb(65, 184, 131);
}
.textLinks {
  text-align: end;
  margin-right: 50px;
}
.textLinksW{
  /* text-align: end; */
  /*margin-right: 50px;*/
  padding: 0;
   color: #acaaaa;
}
.ganzlinks{
  padding: 0;
}
.textLinksR {
  text-align: end;
  margin-right: 50px;
   color: #8b2229;
}
.imgCH {
  position: relative;
}
.spanCH {
  position: absolute;
  bottom: 1px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  /* background-color: rgba(253, 253, 253, 0.5);
  font-size: 20px;
  color: #404040;*/
  background-color: rgba(253, 253, 253, 0.705);
  font-size: 25px;
   /* font-weight: bold;*/
  color: #8b2229;
 /* padding: 10px;*/
}

.form-check-input:checked{
    background-color: #8b2229;
    border-color: #8b2229;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.carousel__slide img {
  border: #000;
  border-style: solid;
  border-width: 1px;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.bildM1 {
  position: relative;
  top: -38px;
  left: 43px;
  max-width: 450px;
}
.slInLeft {
  animation-duration: 2s;
  animation-name: slideinLeft;
}
@keyframes slideinLeft {
  from {
    margin-left: 0%;
    width: 0%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.slInCenter {
  animation-duration: 2s;
  animation-name: slInCenter;
}
@keyframes slInCenter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.ele {
  animation: 4s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}
.ele3 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
.ele3_1 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  visibility: hidden;
}
.ele3_2 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  visibility: hidden;
}
.ele3_3 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 3s;
  visibility: hidden;
}
.ele3_4 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 4s;
  visibility: hidden;
}
.ele3_5 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  visibility: hidden;
}
.ele3_6 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 6s;
  visibility: hidden;
}
.ele3_7 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 7s;
  visibility: hidden;
}
.ele3_8 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 8s;
  visibility: hidden;
}
.ele3_9 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 9s;
  visibility: hidden;
}
.ele3_10 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 10s;
  visibility: hidden;
}
.ele3_11 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 11s;
  visibility: hidden;
}
.ele3_12 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 12s;
  visibility: hidden;
}
.ele3_13 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 13s;
  visibility: hidden;
}
.ele3_14 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 14s;
  visibility: hidden;
}
.ele3_15 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 15s;
  visibility: hidden;
}
.ele3_16 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 16s;
  visibility: hidden;
}
.ele3_17 {
  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 17s;
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 990px) {
.nav-deactiv{
  font-size: 12px;
}

.videoClassGross {
  display: none;
}
.bildM1 {
    position: relative;
top:0;
    left: 43px;
    max-width: 260px;
    margin-top: 1px;
  }
  .nav{
    display: block;
  }
}
@media screen and (min-width: 990px) {
  .titleHeight {
    height: 64px;
  }
  
  .videoClassKlein {
    display: none;
  }
}
</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Inconsolata|Oswald");
#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
