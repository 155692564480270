import mainRoutes from "./main-router";
import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";
import produkteRoutes from "./produkte-router";
import leistungenRoutes from "./leistungen-router";
import serviceRoutes from "./service-router";
import aktuellesRoutesRef from "./aktuelles-router-ref";
import aktuellesRoutesStell from "./aktuelles-router-stell";
import aktuellesRoutesOnl from "./aktuelles-router-onl";
import kontaktRoutes from "./kontakt-router";
import sitemapRoutes from "./sitemap-router";

const routes = [...mainRoutes, 
                ...impressumRoutes, 
                ...datenschutzRoutes,
                ...produkteRoutes,
                ...leistungenRoutes,
                ...serviceRoutes,
                ...aktuellesRoutesRef,
                ...aktuellesRoutesStell,
                ...aktuellesRoutesOnl,
                ...kontaktRoutes,
                ...sitemapRoutes,
            ];

export default routes;
