import { createStore } from "vuex";

import mainModule from "./modules/main";

const store = createStore({
  modules: {  

    main:mainModule,
  },
});

export default store;
